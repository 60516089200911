<template>
  <div class="layout--full-page">
    <router-view></router-view>
    <the-footer></the-footer>
  </div>
</template>

<script>
import TheFooter from "@/layouts/full-page/TheFooter.vue";
export default{
  components: {
    TheFooter
  },
}
</script>
